<template>
  <van-nav-bar :title="title" :border="border" :fixed="fixed" z-index="10" :safe-area-inset-top="fixed"
    :placeholder="fixed">
    <template v-if="leftArrow" #left>
      <slot name="left">
        <van-icon name="arrow-left" color="var(--white)" @click="leftClick" />
      </slot>
    </template>
    <template #title>
      <slot name="title"></slot>
    </template>
    <template #right>
      <slot name="right"></slot>
    </template>
  </van-nav-bar>
</template>
<script>
import { NavBar, Icon } from "vant";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon
  },
  props: {
    border: Boolean,
    title: {
      type: String,
      default: ""
    },
    fixed: Boolean,
    // safeAreaInsetTop: Boolean,
    // placeholder: Boolean,
    left: Boolean,
    leftArrow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    leftClick() {
      if (this.left) return this.$emit('leftClick');
      this.$router.back();
    }
  },
}
</script>